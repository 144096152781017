import { For, Show } from "solid-js";

export function SideTaskInfo(props: { description: string; tips: string }) {
  const tips: string[] = props.tips?.split("$$") ?? [""];

  return (
    <section>
      <p class="text-16px leading-22.4px py-2 text-justify">{props.description ?? "No Description Provided"}</p>
      <div id="tip-container" class="flex items-start gap-10px bg#n-50 p-10px">
        <div>
          <i class="icon-local:tip-blue !w-24px !h-24px block" />
        </div>
        <Show when={tips && tips.length > 0} fallback={<p>No Tips Provided</p>}>
          <div class="flex flex-col space-y-4 text-justify">
            <For each={tips}>
              {(item, index) => {
                return <p>{item}</p>;
              }}
            </For>
          </div>
        </Show>
      </div>
    </section>
  );
}
